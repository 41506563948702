'use strict';
import FluxConstant from 'flux-constant';


export default FluxConstant.set([
    'FORGOT',
    'FORGOT_RESET',
    'FORGOT_RESPONSE',
    'LOGIN',
    'LOGIN_RESPONSE',
    'LOGIN_CLEAR',
    'LOGOUT',
    'LOGOUT_RESPONSE',
    'SWITCH_ACCOUNTS',
    'SWITCH_ACCOUNTS_RESPONSE',
    'ADD_OACCOUNT',
    'ADD_OACCOUNT_RESPONSE',
    'RESET',
    'RESET_RESPONSE',

    'FINISH_CREATING_ACCOUNT',
    'FINISH_CREATING_ACCOUNT_RESET',
    'FINISH_CREATING_ACCOUNT_RESPONSE',

    'SEND_LOGIN_LINK',
    'SEND_LOGIN_LINK_RESET',
    'SEND_LOGIN_LINK_RESPONSE',

    'LOGIN_WITH_TOKEN',
    'LOGIN_WITH_TOKEN_RESPONSE',
]);
