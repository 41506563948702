'use strict';

import Constants from '../../constants';
import ObjectAssign from 'object-assign';
import ParseValidation from '../../../../helpers/parse-validation';


const initialState = {
    loading: false,
    success: false,
    error: undefined,
    hasError: {},
    help: {}
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.FORGOT_RESET) {
        return ObjectAssign({}, initialState, { hasError: {}, help: {} });
    }

    if (action.type === Constants.FORGOT) {
        return ObjectAssign({}, state, {
            loading: true
        });
    }

    if (action.type === Constants.FORGOT_RESPONSE) {
        const validation = ParseValidation(action.response);

        return ObjectAssign({}, state, {
            loading: false,
            success: !action.err,
            error: validation.error,
            hasError: validation.hasError,
            help: validation.help
        });
    }

    return state;
};


export default reducer;
