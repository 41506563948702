'use strict';

import Constants from '../constants.js';
import ObjectAssign from 'object-assign';

const initialState = {
    register_success: false,
    auto_login: false,
    auto_login_email: undefined,
    auto_login_password: undefined,
    choice_register: true,//show register or sign in
    choice_forgot: false//show forgot, overrides register or signin

};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.SIGNIN) {

        return ObjectAssign({}, state, {
            choice_register : false
        });
    }

    if (action.type === Constants.REGISTER) {

        return ObjectAssign({}, state, {
            choice_register : true
        });
    }

    if (action.type === Constants.REGISTER_SUCCESS) {

        return ObjectAssign({}, state, {
            register_success : true
        });
    }

    if (action.type === Constants.REGISTER_SUCCESS_AUTO_LOGIN_CLEAR) {

        return ObjectAssign({}, state, {
            auto_login_email: undefined,
            auto_login_password: undefined
        });
    }

    if (action.type === Constants.REGISTER_SUCCESS_AUTO_LOGIN) {

        return ObjectAssign({}, state, {
            register_success : true,
            auto_login: true,
            auto_login_email: action.email,
            auto_login_password: action.password
        });
    }

    if (action.type === Constants.FORGOT) {

        return ObjectAssign({}, state, {
            choice_forgot : true
        });
    }

    if (action.type === Constants.FORGOT_CONTINUE) {

        return ObjectAssign({}, state, {
            choice_forgot : false,
            choice_register: false
        });
    }

    if (action.type === Constants.FORGOT_CANCEL) {

        return ObjectAssign({}, state, {
            choice_forgot : false
        });
    }

    return state;

};


export default reducer;
