'use strict';

import FluxConstant from 'flux-constant';


export default FluxConstant.set([
    'LOGIN',
    'LOGIN_RESPONSE',
    'LOGIN_CLEAR',
    'SIGNIN',
    'REGISTER',
    'REGISTER_SUCCESS',
    'REGISTER_SUCCESS_AUTO_LOGIN',
    'REGISTER_SUCCESS_AUTO_LOGIN_CLEAR',
    'FORGOT',
    'FORGOT_CANCEL',
    'FORGOT_CONTINUE'
]);
