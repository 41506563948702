'use strict';

import FluxConstant from 'flux-constant';


export default FluxConstant.set([
    'REGISTER',
    'REGISTER_RESPONSE',
    'REGISTER_RESET',
    'CLIENT_SIDE_CHECK'
]);
