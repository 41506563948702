'use strict';

import Constants from '../constants';
import ObjectAssign from 'object-assign';
import ParseValidation from '../../../helpers/parse-validation.js';


const initialState = {
    loading: false,
    success: false,
    error: undefined,
    err: undefined,
    hasError: {},
    help: {}
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.LOGIN_CLEAR) {
        return ObjectAssign({}, state, {
            loading: false,
            success: false,
            error: undefined,
            err: undefined,
            hasError: {},
            help: {}
        });
    }

    if (action.type === Constants.LOGIN) {
        return ObjectAssign({}, state, {
            loading: true,
            error: undefined,
            err: undefined
        });
    }

    if (action.type === Constants.LOGIN_RESPONSE) {
        const validation = ParseValidation(action.response);

        const err = action.err;

        const stateUpdates = ObjectAssign({}, state, {
            loading: false,
            err
        });

        if ( err && !validation.error) {
            stateUpdates.error = action.err.message;

        }
        else {
            if ( !validation.error ) {

            }

            stateUpdates.status = !err;

            stateUpdates.error = validation.error;
            stateUpdates.hasError = validation.hasError;
            stateUpdates.help = validation.help;
        }

        return ObjectAssign({}, state, stateUpdates);
    }

    return state;
};


export default reducer;
