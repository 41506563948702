'use strict';

const ROOT_ORGANIZATION_ID = '11111111-1111-1111-1111-111111111111';
const ROOT_ADMIN_ID = '11111111-1111-1111-1111-111111111111';
const TOS_ID = '11111111-1111-1111-1111-111111111111';
const BF_ORGANIZATION_ID = '2ab6abb0-9200-11e8-afbc-c5664a43a23b';
const { ASSETS_BUCKET, PRIVATE_ASSETS_BUCKET, PRIVATE_GENERATED_FILES_BUCKET, PDF_GENERATION_DOMAIN, OUTCOME_DOMAIN, RESEND_REVIEW_DAYS, TOTAL_RECOMMENDED_TRIALS_PER_HOUR, NODE_ENV } = process.env;
const GA_TARGET_ID ='UA-140887642-1';
const SUBTARGET_ID = '00000000-0000-0000-0000-000000000000';
export {
    ROOT_ORGANIZATION_ID,
    ROOT_ADMIN_ID,
    TOS_ID,
    BF_ORGANIZATION_ID,
    ASSETS_BUCKET,
    PRIVATE_ASSETS_BUCKET,
    PRIVATE_GENERATED_FILES_BUCKET,
    GA_TARGET_ID,
    SUBTARGET_ID,
    PDF_GENERATION_DOMAIN,
    OUTCOME_DOMAIN,
    RESEND_REVIEW_DAYS,
    TOTAL_RECOMMENDED_TRIALS_PER_HOUR,
    NODE_ENV,
};

export default {
    ROOT_ORGANIZATION_ID,
    ROOT_ADMIN_ID,
    TOS_ID,
    BF_ORGANIZATION_ID,
    ASSETS_BUCKET,
    PRIVATE_ASSETS_BUCKET,
    PRIVATE_GENERATED_FILES_BUCKET,
    GA_TARGET_ID,
    SUBTARGET_ID,
    PDF_GENERATION_DOMAIN,
    OUTCOME_DOMAIN,
    RESEND_REVIEW_DAYS,
    TOTAL_RECOMMENDED_TRIALS_PER_HOUR,
    NODE_ENV
};
