'use strict';

import Constants from '../constants';
import ObjectAssign from 'object-assign';


const initialState = {
    hydrated: false,
    loading: false,
    pending: {},
    error: undefined,
    items: []
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.GET_RESULTS) {
        return ObjectAssign({}, state, {
            hydrated: false,
            loading: true
        });
    }

    if (action.type === Constants.GET_RESULTS_RESPONSE) {

        const stateUpdates = ObjectAssign({}, state, {
            hydrated: true,
            loading: false,
            error: action.err
        });
        if ( !action.err ) {
            stateUpdates.items = action.response;
        }

        return stateUpdates;
    }

    if (action.type === Constants.ADD_ITEM) {
        const pending = ObjectAssign({}, state.pending);
        const key = action.request.data.type + '-' + action.request.data.id;
        pending[key] = true;
        return ObjectAssign({}, state, {
            loading: true,
            pending
        });
    }

    if (action.type === Constants.ADD_ITEM_RESPONSE) {
        const key = action.request.data.type + '-' + action.request.data.id;
        const pending = ObjectAssign({}, state.pending);
        delete pending[key];

        return ObjectAssign({}, state, {
            loading: false,
            pending,
            items: action.response
        });
    }

    if (action.type === Constants.DELETE_ITEM) {
        const pending = ObjectAssign({}, state.pending);
        const url = action.request.url;
        const id = url.substring(url.lastIndexOf('/'));
        pending[id] = true;
        return ObjectAssign({}, state, {
            loading: true,
            pending
        });
    }

    if (action.type === Constants.DELETE_ITEM_RESPONSE) {
        const pending = ObjectAssign({}, state.pending);
        const url = action.request.url;
        const id = url.substring(url.lastIndexOf('/'));
        delete pending[id];
        return ObjectAssign({}, state, {
            loading: false,
            pending,
            items: action.response
        });
    }

    return state;
};


export default reducer;
