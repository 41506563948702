'use strict';

import Constants from '../constants';
import ObjectAssign from 'object-assign';


const initialState = {
    hydrated: false,
    loading: false,
    pending: {},
    error: undefined,
    items: [],
    id: undefined,
    total: 0
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.GET_CART_PRODUCTS_RESULTS) {
        return ObjectAssign({}, state, {
            hydrated: false,
            loading: true
        });
    }

    if (action.type === Constants.GET_CART_PRODUCTS_RESULTS_RESPONSE) {
        if ( !action.err ) {

            return ObjectAssign({}, state, {
                hydrated: true,
                loading: false,
                cart_id: action.response.cart_id,
                items: action.response.items,
                total: action.response.total
            });
        }

        return ObjectAssign({}, state, {
            hydrated: true,
            loading: false,
            err: action.err,//is this used?
            error: action.err.message
        });
    }

    return state;
};


export default reducer;
