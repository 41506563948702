

import Constants from '../constants';
import ObjectAssign from 'object-assign';
import ParseValidation from '../../../helpers/parse-validation';
// hapi/joi requires TextEncoder which is not available on some recent-ish browsers
// not sure why hapi/joi doesn't get transpile to es 5
//import Joi from '@hapi/joi';
import Regexs from '../../../helpers/regexes';


const initialState = {
    loading: false,
    success: false,
    error: undefined,
    hasError: {},
    autoFocus: 'email',
    help: {}
};

/*
const schema = Joi.object({
    email: Joi.string().email({ minDomainSegments: 2, tlds: false }).lowercase().required().label('Email'),
    password: Joi.string().min(6).required().label('Password'),
    password_confirm: Joi.string().required().label('Confirm Password'),
    //this used to work with joi-browser but doesn't work with joi
    //password_confirm: Joi.string().required().label('Confirm').valid(Joi.ref('password')).options({
    //    language: {
    //        any: {
    //            allowOnly: '!!Passwords do not match'
    //        }
    //    }
    //}),
    first: Joi.string().required().min(1).label('First'),
    last: Joi.string().required().min(1).label('Last'),
});
*/

const reducer = function (state = initialState, action) {

    if (action.type === Constants.REGISTER_RESET) {
        return ObjectAssign({}, initialState, { hasError: {}, help: {} });
    }

    if (action.type === Constants.CLIENT_SIDE_CHECK) {

        const data = action.data;
        const check = ObjectAssign({}, initialState, { error: null, hasError: {}, help: {} } );
        check.setError = function(f, msg) {
            this.hasError[f] = true;
            this.help[f] =  msg;
            this.autoFocus = f;
            this.error = 'There were errors with your registration.  Please see below.';
        };

        if ( !Regexs.email.test(data.email)){
            check.setError('email', 'Invalid Email')
        }

        if ( data.password.length < 8) {
            check.setError('password', 'Password too short');
        } else if ( data.password !== data.password_confirm) {
            check.setError('password_confirm', 'Passwords do not match');
        }

        let count = 0;
        if ( data.password.match(/[A-Z]/)) {
            count++;
        }

        if ( data.password.match(/[a-z]/)) {
            count++;
        }

        if ( data.password.match(/[0-9]/)) {
            count++;
        }

        if ( data.password.match(/\W/)) {
            count++;
        }

        if ( count < 3) {
            check.setError('password', 'Password must include at least 1 character from at least 3 of the following: upper case, lower case, numeric, and non alphanumeric characters.');
        }

        if ( data.first.length < 1 ) {
            check.setError('first', 'First name is required');
        }

        if ( data.last.length < 1 ) {
            check.setError('last', 'Last name is required');
        }

        /*
        const result = schema.validate(data, { abortEarly : false });
        if ( result.error ) {
            check.success = false;
            check.error = 'There were errors with your registration.  Please see below.';
            result.error.details.forEach( (f) => {

                f.path.forEach( (p) => {

                    if ( !check.autoFocus ) {
                        check.autoFocus = p;
                    }

                    check.hasError[p] = true;
                    check.help[p] = ( check.help[p] ? check.help[p] : ' ') + ' ' + f.message + '.';
                });
            });
        }
        else if ( data.password !== data.password_confirm) {
            //this is a work around because I don't know how to get joi to give a good error message when
            //password and password_confirm don't match
            check.success = false;
            check.error = 'There were errors with your registration.  Please see below.';
            check.hasError['password'] = true;
            check.help['password'] = 'Passwords do not match.';
        }*/

        return check;
    }

    if (action.type === Constants.REGISTER) {

        return ObjectAssign({}, state, {
            loading: true
        });
    }

    if (action.type === Constants.REGISTER_RESPONSE) {

        if ( action.response.statusCode === 409 ) {
            //email already in use, doesn't come down like other validation errors
            return ObjectAssign({}, state, {
                loading: false,
                success: false,
                error: 'Email already in use.',
                hasError: { 'email': true, 'email_in_use': true },
                help: { 'email': 'Email already in use.' }
            });
        }

        const validation = ParseValidation(action.response);

        return ObjectAssign({}, state, {
            loading: false,
            success: !action.err,
            error: validation.error,
            hasError: validation.hasError,
            help: validation.help
        });
    }

    return state;
};

export default reducer;
