'use strict';
import FluxConstant from 'flux-constant';


export default FluxConstant.set([
    'GET_MY_ACCOUNT',
    'GET_MY_ACCOUNT_RESPONSE',
    'GET_RESULTS',
    'GET_RESULTS_RESPONSE',
    'GET_CART_PRODUCTS_RESULTS',
    'GET_CART_PRODUCTS_RESULTS_RESPONSE',
    'ADD_ITEM',
    'ADD_ITEM_RESPONSE',
    'DELETE_ITEM',
    'DELETE_ITEM_RESPONSE',
    'SHOW_REGISTER_OR_SIGN_IN',
    'CANCEL_CHECKOUT',
    'LOGIN',
    'LOGIN_RESPONSE',
    'SIGNIN',
    'REGISTER',
    'REGISTER_SUCCESS',
    'FORGOT',
    'FORGOT_CANCEL',
    'FORGOT_CONTINUE'
]);
