/* global window */
'use strict';

import ApiActions from '../../../actions/api';
import Constants from './constants';
import Store from './store';
import GlobalConstants from '../../../global-constants.js';

class Actions {

    static getMyAccount(store = Store) {

        ApiActions.get(
            '/api/accounts/mycheck',
            undefined,
            store,
            Constants.GET_MY_ACCOUNT,
            Constants.GET_MY_ACCOUNT_RESPONSE
        );
    }
    static getResults(store = Store, init) {

        const state = store.getState();
        if ( init && (state.cart.hydrated || state.cart.loading)) {
            return;
        }

        //fixed for now
        const oId = GlobalConstants.ROOT_ORGANIZATION_ID;
        ApiActions.get(
            `/api/organizations/${oId}/cart`,
            null,
            store,
            Constants.GET_RESULTS,
            Constants.GET_RESULTS_RESPONSE
        );
    }
    static getProductResults(store = Store, init) {

        const state = store.getState();
        if ( init && (state.cartContents.hydrated || state.cartContents.loading)) {
            return;
        }

        //fixed for now
        ApiActions.get(
            `/api/cart/purchasables`,
            null,
            store,
            Constants.GET_CART_PRODUCTS_RESULTS,
            Constants.GET_CART_PRODUCTS_RESULTS_RESPONSE
        );
    }
    static addItem(store = Store, id, oAccountId, type) {

        //fixed for now
        const oId = GlobalConstants.ROOT_ORGANIZATION_ID;
        ApiActions.post(
            `/api/organizations/${oId}/cart`,
            { id, type, accounts: [oAccountId], locations: [], positions: [] },
            store,
            Constants.ADD_ITEM,
            Constants.ADD_ITEM_RESPONSE
        );
    }
    static deleteItem(store = Store, id, type, cb) {

        //fixed for now
        const oId = GlobalConstants.ROOT_ORGANIZATION_ID;
        ApiActions.delete(
            `/api/organizations/${oId}/cart/` + type + '-' + id,
            null,
            store,
            Constants.DELETE_ITEM,
            Constants.DELETE_ITEM_RESPONSE,
            cb
        );
    }

}


export default Actions;
