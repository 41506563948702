'use strict';

import * as Redux from 'redux';
import Cart from './reducers/cart';
import CartContents from './reducers/cart-contents';
import My from '../../login/signin-or-register/reducers/my';
import SigninOrRegister from '../../login/signin-or-register/reducers/signin-or-register.js';
import Login from '../../login/reducers/login.js';
import Register from '../../signup/reducers/register.js';
import Forgot from '../../login/forgot/reducers/forgot.js';


export default Redux.createStore(
    Redux.combineReducers({
        my: My,
        cartContents: CartContents,
        cart: Cart,
        signinOrRegister: SigninOrRegister,
        login: Login,
        register: Register,
        forgot: Forgot
    })
);
