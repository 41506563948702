'use strict';

import Constants from '../constants.js';
import ObjectAssign from 'object-assign';

const initialState = {
    hydrated: false,
    loading: false,
    showSaveSuccess: false,
    error: undefined,
    hasError: {},
    help: {},
    notFound: false,
    account: {
        User: { username: '' },
        OAccounts: []
    },
    admin: {},
    roles: {}
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.GET_MY_ACCOUNT) {

        return ObjectAssign({}, state, {
            loading : true,
            hydrated : false
        });

    }

    if (action.type === Constants.GET_MY_ACCOUNT_RESPONSE) {

        if ( action.response && action.response.statusCode === 404) {
            return ObjectAssign({}, state, {
                notFound : true,
                hydrated: true,
                loading: false
            });
        }

        if ( !action.err) {
            return ObjectAssign({}, state, {
                account : action.response
            });
        }

        return ObjectAssign({}, state, {
            error : action.err
        });
    }

    return state;
};


export default reducer;
